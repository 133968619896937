import { Carousel, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import custmer1 from '../../images/custmer1.png'
import custmer2 from '../../images/custmer2.png'
import custmer3 from '../../images/custmer3.png'
import custmer4 from '../../images/custmer4.png'
import custmer5 from '../../images/custmer5.png'
import custmer6 from '../../images/custmer6.png'
import custmer7 from '../../images/custmer7.png'
import custmer8 from '../../images/custmer8.png'
import custmer9 from '../../images/custmer9.png'
import custmer10 from '../../images/custmer10.png'
import custmer11 from '../../images/custmer11.png'
import custmer12 from '../../images/custmer12.png'
import custmer13 from '../../images/custmer13.png'
import custmer14 from '../../images/custmer14.png'
import custmer15 from '../../images/custmer15.png'
import './index.css'

const CustomerPage = () => {
    const [imglist] = useState([
        {
            name: "成都市第七人民医院",
            imgurl: custmer1
        },{
            name: "西充县人民医院",
            imgurl: custmer2
        },{
            name: "南充市第五人民医院",
            imgurl: custmer3
        },{
            name: "右江民族医学院附属医院",
            imgurl: custmer4
        },{
            name: "百色市人民医院",
            imgurl: custmer5
        },{
            name: "武宣县人民医院",
            imgurl: custmer6
        },{
            name: "武宣县中医医院",
            imgurl: custmer7
        },{
            name: "田东县中医医院",
            imgurl: custmer8
        },{
            name: "田阳区人民医院",
            imgurl: custmer9
        },{
            name: "田阳区中医医院",
            imgurl: custmer10
        },{
            name: "德保县人民医院",
            imgurl: custmer11
        },{
            name: "田东康弘医院",
            imgurl: custmer12
        },{
            name: "青岛西海岸新区人民医院",
            imgurl: custmer13
        },{
            name: "青岛市城阳人民医院",
            imgurl: custmer14
        },{
            name: "金华市第二医院",
            imgurl: custmer15
        },
    ]);
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    return (
        <div className='CustomerPage'>
            <div className='main'>
                <div className='banner_box'>
                    <Carousel afterChange={onChange}>
                        <div className='banner banner1'>
                            <div>
                                <div className='banner_title'>
                                    合作案例
                                </div>
                                <div className='banner_content'>
                                    聚焦医院运营指标数据平台，并通过SaaS模式为医院管理提质量、增效益贡献力量。
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className='con'>
                    <Row>
                        {imglist.map(el=>{
                            return <Col span={8}>
                                <div className='item'>
                                    <img src={el.imgurl} />
                                    <p>{el.name}</p>
                                </div>
                            </Col>
                        })}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default CustomerPage;
