import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import MainContent from './routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
// let children = MainContent();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* {children} */}
      <MainContent />
    </BrowserRouter>
  </React.StrictMode>
);
// import * as React from "react";
// import * as ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   root
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
