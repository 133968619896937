// import * as React from "react";
import Routes from "./routes";
import {
  createBrowserRouter,
  RouterProvider,
  Routes as Router,
  Route
} from "react-router-dom";
import { Layout } from "antd";
import Header from "../compoments/Header";
import Footer from "../compoments/Footer";

const MainContent = () => {
  const { Content } = Layout;
  const router = createBrowserRouter(Routes);
  return (
    // <Router>
      <Layout>
        <Header></Header>
        <Content
          style={{
            position: "relative",
            top: "0px",
            bottom: "415px",
            background: "#F9FAFB",
          }}
        >
          {/* {router} */}
          {/* <RouterProvider router={router} /> */}
          <Router>
          {Routes.map((i)=>{
            return <Route key={i.path} path={i.path} element={i.element} />
          })}
          </Router>
          
        </Content>
        <Footer>Footer</Footer>
      </Layout>
    // </Router>
  );
};

export default MainContent;
