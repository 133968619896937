import { Row, Col, Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import quar from '../../images/quar.png';
import pc from '../../images/pc.png';
import producticon1 from '../../images/producticon1.png';
import producticon2 from '../../images/producticon2.png';
import producticon3 from '../../images/producticon3.png';
import producticon4 from '../../images/producticon4.png';
import yyzdbg from '../../images/yyzdbg.png';
import shujuduijie from '../../images/shujuduijie.png';
import saomiao from '../../images/saomiao.png';
import zbtjfxbg from '../../images/zbtjfxbg.png';
import zhibiao2 from '../../images/zhibiao2.png';
import tousujianyi from '../../images/tousujianyi.png';
import wsgzlcbg from '../../images/wsgzlcbg.png';
import qmfztsbg from '../../images/qmfztsbg.png';
import liucheng from '../../images/liucheng.png';
import jiankongpingtai from '../../images/jiankongpingtai.png';
import dashujushujutongji from '../../images/dashujushujutongji.png';
import yunyingguanli1 from '../../images/yunyingguanli1.png';
import kuaisuduijie from '../../images/kuaisuduijie.png';
import lcyszkbg from '../../images/lcyszkbg.png';
import xiaoyanjieguochaxun from '../../images/xiaoyanjieguochaxun.png';
import dbmlhzk from '../../images/dbmlhzk.png';
import scybjsqdbg from '../../images/scybjsqdbg.png';
import xiangqing from '../../images/xiangqing.png';
import jiankong from '../../images/jiankong.png';
import djgjptsjbg from '../../images/djgjptsjbg.png';
import shangchuan from '../../images/shangchuan.png';
import qianbao2 from '../../images/qianbao2.png';
import shenpizhong from '../../images/shenpizhong.png';
import hzjcbg from '../../images/hzjcbg.png';
import xinxi from '../../images/xinxi.png';
import yujingquanzu from '../../images/yujingquanzu.png';
import hzyjbg from '../../images/hzyjbg.png';
import tixing from '../../images/tixing.png';
import irclecheck from '../../images/irclecheck.png';
import bajcbg from '../../images/bajcbg.png';
import feiyong from '../../images/feiyong.png';
import zhikongicon from '../../images/zhikongicon.png';
import shycbg from '../../images/shycbg.png';
import guidangwenjianlei from '../../images/guidangwenjianlei.png';
import saomaheyan from '../../images/saomaheyan.png';
import qyfxbg from '../../images/qyfxbg.png';
import yulan from '../../images/yulan.png';
import duoweifenxi from '../../images/duoweifenxi.png';
import ksfxbg from '../../images/ksfxbg.png';
import shouyijisuanqi from '../../images/shouyijisuanqi.png';
import fengxianyujing from '../../images/fengxianyujing.png';
import zzzbg from '../../images/zzzbg.png';
import zzbg from '../../images/zzbg.png';
import zhinengzhizaozhenduan from '../../images/zhinengzhizaozhenduan.png';
import advpc from '../../images/advpc.png';
import msgicon from '../../images/msgicon.png';
import serveicon from '../../images/serveicon.png';
import seticon from '../../images/seticon.png';
import pageicon from '../../images/pageicon.png';
import prosevhos from '../../images/prosevhos.png';

import './index.css'

const ProductservicePage = () => {
    const [fnproduce,setFnproduce] = useState([
        {
            name: "医院绩效考核模块",
            icon: producticon1,
            check: true,
            list: [
                {
                    name: '整体运营诊断',
                    bgimg: yyzdbg,
                    list: [
                        {
                            icon: shujuduijie,
                            text: '与院内信息系统对接，进行数据质量评测及清洗质控。'
                        },{
                            icon: saomiao,
                            text: '结合医院工作流进行全身扫描，智能化诊断可提升维度。'
                        }
                    ]
                },{
                    name: '指标统计分析',
                    bgimg: zbtjfxbg,
                    list: [
                        {
                            icon: zhibiao2,
                            text: '分析指标结果，查找医院管理工作中存在的系统性短板。'
                        },{
                            icon: tousujianyi,
                            text: '结合分析结果，为医院管理提质量、增效益提供专业建议。'
                        }
                    ]
                },{
                    name: '完善工作流程',
                    bgimg: wsgzlcbg,
                    list: [
                        {
                            icon: liucheng,
                            text: '业务流程设计采⽤分⻆⾊流程控制⽅式，自定义院内工作流。'
                        },{
                            icon: jiankongpingtai,
                            text: '对工作流程进行系统性监控，流程审批可追溯，提升工作效率。'
                        }
                    ]
                },{
                    name: '全面辅助提升',
                    bgimg: qmfztsbg,
                    list: [
                        {
                            icon: dashujushujutongji,
                            text: '辅助医院提升数据质量，保证医院数据统计的及时性与准确性。'
                        },{
                            icon: yunyingguanli1,
                            text: '运营管理人员聚焦数据分析，全面提升医院整体运营管理水平。'
                        }
                    ]
                }
            ]
        },{
            name: "医保结算清单模块",
            icon: producticon2,
            check: false,
            list: [
                {
                    name: '临床医生端质控',
                    bgimg: lcyszkbg,
                    list: [
                        {
                            icon: kuaisuduijie,
                            text: '与院内信息系统对接，进行数据质量评测及清洗质控。'
                        },{
                            icon: xiaoyanjieguochaxun,
                            text: '结算清单质控引擎对清单进行校验并展示校验结果。'
                        }
                    ]
                },{
                    name: '多部门联合质控',
                    bgimg: dbmlhzk,
                    list: [
                        {
                            icon: shenpizhong,
                            text: '融入医院管理流程，分医疗组、信息小组、财务小组质控审批。'
                        },{
                            icon: tousujianyi,
                            text: '结合质控结果，为医院清单修订提供专业建议。'
                        }
                    ]
                },{
                    name: '生成医保结算清单',
                    bgimg: scybjsqdbg,
                    list: [
                        {
                            icon: xiangqing,
                            text: '自动生成医保结算清单，并展示清单详情。'
                        },{
                            icon: jiankong,
                            text: '对清单内容进行系统性监控，快速追踪问题源头。'
                        }
                    ]
                },{
                    name: '对接国家平台数据',
                    bgimg: djgjptsjbg,
                    list: [
                        {
                            icon: shangchuan,
                            text: '上传前辅助医院提升清单质量，保证医院数据的规范性与准确性。'
                        },{
                            icon: qianbao2,
                            text: '对接医保数据平台接口，并及时有效上传，搭建医院数据盈利模式。'
                        }
                    ]
                }
            ]
        },{
            name: "DRG临床监测模块",
            icon: producticon3,
            check: false,
            list: [
                {
                    name: '患者住院期间\n实时动态监测',
                    bgimg: hzjcbg,
                    list: [
                        {
                            icon: xinxi,
                            text: '在临床医生工作站中添加DRG临床监测，可主动随时触发查看患者分组信息。'
                        },{
                            icon: yujingquanzu,
                            text: '对住院期间病例进行实时分组校验及费用预警，结合监测合理规划临床路径。'
                        }
                    ]
                },{
                    name: '患者出院时\n分组监测预警',
                    bgimg: hzyjbg,
                    list: [
                        {
                            icon: tixing,
                            text: '在患者预出院时，触发DRG分组监测预警，系统主动提醒分组异常情况。'
                        },{
                            icon: irclecheck,
                            text: '辅助医生对未入组或高倍率等病例诊疗信息核对，减少病案打回，提升工作质量。'
                        }
                    ]
                },{
                    name: '病案/清单修订时\n分组监测',
                    bgimg: bajcbg,
                    list: [
                        {
                            icon: feiyong,
                            text: '与院内病案首页系统对接，添加该模块并智能化提醒分组及病组均费。'
                        },{
                            icon: zhikongicon,
                            text: '对病案首页/医保结算清单内容进行分组质控，为病案修订提供数据支撑。'
                        }
                    ]
                },{
                    name: '数据上传医保时\n分组预测',
                    bgimg: shycbg,
                    list: [
                        {
                            icon: guidangwenjianlei,
                            text: '病案归档后可实时查看分组及费用预警，上传前保证医院收益趋势。'
                        },{
                            icon: saomaheyan,
                            text: '数据上传医保时进行智能化提醒，在最终环节进行分组质控及数据核验。'
                        }
                    ]
                }
            ]
        },{
            name: "DRG智慧管理模块",
            icon: producticon4,
            check: false,
            list: [
                {
                    name: '全院DRG下钻分析',
                    bgimg: qyfxbg,
                    list: [
                        {
                            icon: yulan,
                            text: '院长层级可对全院医保DRG运营情况预览，及时掌控医院总体盈亏水平。'
                        },{
                            icon: duoweifenxi,
                            text: '对全院所有科室进行DRG结算总体结果对比分析，精细化管控到科室。'
                        }
                    ]
                },{
                    name: '科室DRG下钻分析',
                    bgimg: ksfxbg,
                    list: [
                        {
                            icon: shouyijisuanqi,
                            text: '临床科室层级可针对科室内DRG病组进行下钻分析，及时介入，提升科室盈余能力。'
                        },{
                            icon: fengxianyujing,
                            text: '相同病组不同科室对比分析，寻找优势病组并合理配置资源，降低超支风险。'
                        }
                    ]
                },{
                    name: '主诊组/医师\nDRG下钻',
                    bgimg: zzbg,
                    list: [
                        {
                            icon: zhinengzhizaozhenduan,
                            text: '主诊组/医师对DRG病组下钻至病例进行分析，及时纠偏，降低不合理费用的发生。'
                        },{
                            icon: zhikongicon,
                            text: '相同病组不同主诊组/医师对比分析，辅助院内进行诊疗路径互享，提升医技和服务质量。'
                        }
                    ]
                },{
                    name: '病例DRG下钻分析',
                    bgimg: zzzbg,
                    list: [
                        {
                            icon: guidangwenjianlei,
                            text: '对医保DRG病例进行下钻明细类别分析，追根溯源，挖掘费用超支主因。'
                        },{
                            icon: saomaheyan,
                            text: '相同病组不同病例对比分析，杜绝高倍率较多、歧义病组等现象，精细化管控医保DRG。'
                        }
                    ]
                }
            ]
        }
    ])
    const [showdetail, setShowdetail] = useState([
        {
            name: '整体运营诊断',
            bgimg: yyzdbg,
            list: [
                {
                    icon: shujuduijie,
                    text: '与院内信息系统对接，进行数据质量评测及清洗质控。'
                },{
                    icon: saomiao,
                    text: '结合医院工作流进行全身扫描，智能化诊断可提升维度。'
                }
            ]
        },{
            name: '指标统计分析',
            bgimg: zbtjfxbg,
            list: [
                {
                    icon: zhibiao2,
                    text: '分析指标结果，查找医院管理工作中存在的系统性短板。'
                },{
                    icon: tousujianyi,
                    text: '结合分析结果，为医院管理提质量、增效益提供专业建议。'
                }
            ]
        },{
            name: '完善工作流程',
            bgimg: wsgzlcbg,
            list: [
                {
                    icon: liucheng,
                    text: '业务流程设计采⽤分⻆⾊流程控制⽅式，自定义院内工作流。'
                },{
                    icon: jiankongpingtai,
                    text: '对工作流程进行系统性监控，流程审批可追溯，提升工作效率。'
                }
            ]
        },{
            name: '全面辅助提升',
            bgimg: qmfztsbg,
            list: [
                {
                    icon: dashujushujutongji,
                    text: '辅助医院提升数据质量，保证医院数据统计的及时性与准确性。'
                },{
                    icon: yunyingguanli1,
                    text: '运营管理人员聚焦数据分析，全面提升医院整体运营管理水平。'
                }
            ]
        }
    ])
    const [index, setIndex] = useState(0)
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const onChange2 = (currentSlide) => {
        console.log(currentSlide);
    };
    const onChangemenu = (index)=>{
        let menu = fnproduce
        let arr = []
        menu.map((el,i)=>{
            if(i==index){
                setShowdetail(el.list)
            }
            arr.push({
                ...el,
                check: i==index?true:false
            })
        })
        setIndex(index)
        setFnproduce(arr)
    }
    return (
        <div className='ProductservicePage'>
            <div className='main'>
                <div className='banner_box'>
                    <Carousel afterChange={onChange}>
                        <div className='banner banner1'>
                            <div>
                                <div className='banner_title'>
                                    医院运营指标数据平台
                                </div>
                                <div className='banner_content'>
                                    基于国家多项政策导向，结合医院内部管理经验契合医院管理发展⽅向开发的⼀款信息化软件产品。
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    <Carousel afterChange={onChange2}>
                        <div className='banner banner2'>
                            <p>产品介绍</p>
                            <div className='prointr'>
                                <p>医疗运营指标数据平台</p>
                                <img src={quar} alt="" />
                                <div>
                                    医院运营指标数据平台是北京安竣科技有限公司与清华大学医院管理研究院长期合作，基于国家多项政策导向，结合医院内部管理经验，契合医院管理发展⽅向开发的⼀款信息化软件产品。安竣以平台为基础，并通过SaaS模式为医院管理提质量、增效益贡献力量。平台包含数据质控、卫健监管、医保付费及医院运营管理等多方面维度，辅助医院进行全面运营诊断与指标数据分析，助力医院优化内部运营管理，有效促进医院高质量发展。
                                </div>
                            </div>
                            <img src={pc} alt="" />
                        </div>
                    </Carousel>
                </div>
                <div className='fuproduce'>
                    <div>
                        <p className='title'>功能介绍</p>
                        <div className=''>
                            <div className='menuBox'>
                                {fnproduce.map((el,i)=>{
                                    return <div className={'item'+(el.check?' checkenitem':'')} onClick={e=>onChangemenu(i)}>
                                        <img src={el.icon}/>
                                        <p>{el.name}</p>
                                    </div>
                                })}
                            </div>
                            <div className='line'></div>
                            <div className={index>1?'itemConLonger itemCon':'itemCon'}>
                                {showdetail.map((el,i)=>{
                                    return <div className='rowItem'>
                                        <div className='titleBox' style={{background: 'url('+el.bgimg+') no-repeat','background-size': '260px 140px'}}>
                                            <span className='title'>
                                                {el.name}
                                            </span>
                                        </div>
                                        {el.list.map(el2=>{
                                            return <div className={index>1?'longer':''}>
                                                <img src={el2.icon} />
                                                {el2.text}
                                            </div>
                                        })}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='advBox'>
                    <Carousel afterChange={onChange}>
                        <div className='banner banner1'>
                            <div className='advcon'>
                                <p>产品优势</p>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={12}>
                                                <div className='item'>
                                                    <img src={msgicon} />
                                                    <p>基础管理</p>
                                                    <div>
                                                        严谨且完善的数据质控逻辑实时响应政策变化，指标更新及时。
                                                    </div>
                                                </div>
                                                <div className='item'>
                                                    <img src={serveicon} />
                                                    <p>服务模式</p>
                                                    <div>
                                                        医院使用模块全部功能，按月付费降低医院使用资金风险和技术风险提供“系统运营服务+咨询服务”相结合的全流程服务。
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='item item1'>
                                                    <img src={seticon} />
                                                    <p>系统功能</p>
                                                    <div>
                                                        根据医院需求搭建定制化指标池，自由建立工作流程，工作小组制人工筛选查询、修改指标结果，工作质量及效率统计分析、可视化展示等智能诊断运营管理现状，生成医院运营管理首页，提供业内专家优化方案。
                                                    </div>
                                                </div>
                                                <div className='item'>
                                                    <img src={pageicon} />
                                                    <p>多家医院共同打磨</p>
                                                    <div>
                                                        医院运营指标数据平台已在多家医院落地应用，长期与清华大学医院管理研究院合作持续打磨系统、迭代优化效果。
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}><img src={advpc} /></Col>
                                </Row>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className='successBox'>
                    <Carousel afterChange={onChange}>
                        <div className='banner banner1'>
                            <div className='succecon'>
                                <p>合作案例</p>
                                <img src={prosevhos} />
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default ProductservicePage;
