import HomePage from "../pages/HomePage";
import ProductservicePage from "../pages/ProductservicePage";
import SolutionPage from "../pages/SolutionPage";
import CustomerPage from "../pages/CustomerPage";
import AboutUsPage from "../pages/AboutUsPage";
import RecruitPage from "../pages/RecruitPage";
import RecruitUpPage from "../pages/RecruitUpPage";

const Routes = [
  {
    path: "/",
    // exact: true,
    // name: '首页',
    element: <HomePage />,
  },{
    path: "/index",
    // exact: true,
    // name: '首页',
    element: <HomePage />,
  },{
    path: "/productservice",
    element: <ProductservicePage />,
  },{
    path: "/solution",
    element: <SolutionPage />,
  },{
    path: "/customer",
    element: <CustomerPage />,
  },{
    path: "/aboutus",
    element: <AboutUsPage />,
  },{
    path: "/recruit",
    element: <RecruitPage />,
  },{
    path: "/recruitup/:name/:type/:location",
    element: <RecruitUpPage />,
  }
];
export default Routes;
