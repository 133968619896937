import { Upload, message, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import './index.css';

const RecruitUpPage = () => {
    const { name, location, type } = useParams();
    const [fileList, setFileList] = useState([]);
    const [fileList1, setFileList1] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [showUploadList, setShowUploadList] = useState(true);
    const props = {
        accept: 'image/*,.pdf,.doc,.docx',
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            console.log(newFileList)
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isLimit10M = file.size/1024/1024 <= 10
            console.log(file.size,isLimit10M)
            if(!isLimit10M){
                message.destroy()
                message.error('文件大小不能超过10M。')
                return Upload.LIST_IGNORE;
            }else{
                setFileList([...fileList, file]);
                setShowUploadList(true)
                return false;
            }
        },
        maxCount: 1,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.destroy()
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.destroy()
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const props1 = {
        accept: 'image/*,.pdf,.doc,.docx',
        onRemove: (file) => {
            const index = fileList1.indexOf(file);
            const newFileList = fileList1.slice();
            newFileList.splice(index, 1);
            setFileList1(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList1, file]);
            return false;
        },
        maxCount: 1,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.destroy()
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.destroy()
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const onSubmit = () => {
        setUploading(true);
        if(!fileList.length){
            setUploading(false);
            message.destroy()
            message.error('请上传简历或附件。')
            return;
        }
        const formData = new FormData();
        let Attachments = [];
        
        fileList.forEach((file,index) => {
            formData.append("files", file);
        });
        let subject = name + type + location;
        // fetch('https://hybo-notice-dev.hybo.cn/notice/public/email/send-one?title='+subject,{
        fetch('/api/notice/public/email/send?title='+subject,{
            method:'POST',
            mode:'cors',
            // body:JSON.stringify({"files":fileList,"title":subject})
            body:formData
        }).then((result)=>{
            return result.json()
        }).then((result)=>{
            console.log(result)
            if(result.code=='200'){
                message.destroy()
                message.success("提交成功！")
                setFileList([]);
                setUploading(false);
                setShowUploadList(false)
            }else{
                message.destroy()
                message.error(result.msg)
                setUploading(false);
            }
        })
    }
    return (
        <div className='RecruitUpBox'>
            <div className='RecruitUp'>
                <div className='title'>
                    <div className='titlename'>{name}</div>
                    <div className='titlecon'>{type} | {location}</div>
                </div>
                <div className='upBox'>
                    <p className='dutytitle'>上传:</p>
                    {/* <form method="post" enctype="multipart/form-data"> */}
                    <div>
                        <div>上传简历</div>
                        {/* <input className='btnref btnref`' type="file" id="file" name="file" /> */}
                        <Upload {...props} showUploadList={showUploadList} name="resume"><div className='btnref btnref1'>上传简历</div></Upload>
                    </div>
                    <div>
                        <div>上传附件</div>
                        {/* <input className='btnref btnref2' type="file" id="file" name="file" /> */}
                        <Upload {...props} showUploadList={showUploadList} name="enclosure"><div className='btnref btnref2'>上传附件</div></Upload>
                    </div>
                    {/* </form> */}
                    <Button className='btnref submit' loading={uploading} onClick={onSubmit}>提交</Button>

                </div>
            </div>
        </div>
    )
}

export default RecruitUpPage;
