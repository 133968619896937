import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import icon1 from '../../images/index_solution_icon_1.png';
import icon2 from '../../images/index_solution_icon_2.png';
import icon3 from '../../images/index_solution_icon_3.png';
import icon4 from '../../images/index_solution_icon_4.png';
import prosevhos from '../../images/prosevhos.png';

const HomePage = () => {
    const onChange = (currentSlide) => {
        // console.log(currentSlide);
    };
    let location = useLocation();
    console.log(location)
    return (
        <div className='HomePage'>
            <div className='banner_box'>
                <Carousel afterChange={onChange} autoplay dots={{className:'dotbox'}}>
                    <div className='banner banner1'>
                        <div className='banner_title'>
                            安竣科技有限公司
                        </div>
                        <div className='banner_content'>
                            专注中国医院运营管理方向，打造医院精细化管理服务
                        </div>
                    </div>
                    <div className='banner banner2'>
                        <div>
                            <div className='banner_title'>
                                医院运营指标数据平台
                            </div>
                            <div className='banner_content'>
                                基于国家多项政策导向，结合医院内部管理经验契合医院管理发展⽅向开发的⼀款信息化软件产品。
                            </div>
                        </div>
                    </div>
                    <div className='banner banner2 banner3'>
                        <div>
                            <div className='banner_title'>
                                医院运营指标数据平台服务方案
                            </div>
                            <div className='banner_content'>
                                辅助医院进行全面运营诊断与指标数据分析，助力医院优化内部运营管理，有效促进医院高质量发展。
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
            <div className='product_box'>
                <div className='product'>
                    <div className='product_title'>
                        强大、安全、稳定的产品及服务
                    </div>
                    <div className='product_subtitle'>
                        <div className='product_subtitle_title'>
                            医疗运营指标数据平台
                        </div>
                        <div className='product_subtitle_subtitle'>
                            辅助医院进行全面运营诊断与指标数据分析，助力医院优化内部运营管理，有效促进医院高质量发展。
                        </div>
                        <Link to="/productservice">
                            <div className='product_subtitle_btn'>
                                了解更多
                            </div>
                        </Link>
                    </div>
                    <div className='product_content'>
                        <div className='modular'>
                            <div className='modular_title'>
                                医院绩效考核模块
                            </div>
                            <div className='modular_content'>
                                基于国家卫健委绩效考核政策结合医院信息系统建⽴绩效考核指标体系，全⽅位⽀持国家监测（综合医院26项/中医院34项）、省市级监测及院内⾃有考核指标体系构建。
                            </div>
                        </div>
                        <div className='modular'>
                            <div className='modular_title'>
                                DRG临床监测模块
                            </div>
                            <div className='modular_content'>
                                结合医保DRG付费政策时间过程中医疗机构实际需求及潜在需求为医院用户构建的数据化、标准化、智能化、信息化的运营管理支持工具。
                            </div>
                        </div>
                        <div className='modular'>
                            <div className='modular_title'>
                                医保结算清单模块
                            </div>
                            <div className='modular_content'>
                                根据医疗保障基金结算清单填写规范及清单数据收集要求，结合医院信息系统建⽴数据质控与优化辅助医院进行清单数据完善，提升医保基金结算清单的数据治理，提升结算经济效益。
                            </div>
                        </div>
                        <div className='modular'>
                            <div className='modular_title'>
                                DRG智慧管理模块
                            </div>
                            <div className='modular_content'>
                                紧随国家医疗保障局医保支付方式改革政策要求，旨在辅助医院进行院内DRG总体管理，实现DRG院内管理和费用结构监控。 同时，DRG付费改革中医院自主管理和数字化智能管理水平。 流程审批可追溯，提升工作效率。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='solution_box'>
                <div className='solution'>
                    <div className='solution_title'>
                        我们提供成熟的解决方案
                    </div>
                    <div className='solution_subtitle'>
                        实现数据集成及质控、工作流程管控、考核指标自定义及分析、运营管理首页生成等功能，有效诊断运营管理现状，分解挖掘指标内涵，优化管理路径，提升运营效率。
                    </div>
                    <div className='solution_content'>
                        <div className='modular first'>
                            <div>
                                <div className='modular_icon'>
                                    <img src={icon1} alt="icon1" />
                                </div>
                                <div className='modular_title'>
                                    整体运营诊断
                                </div>
                                <div className='modular_content'>
                                    结合医院工作流进行全身扫描，智能化诊断可提升维度。
                                </div>
                            </div>
                        </div>
                        <div className='modular second'>
                            <div>
                                <div className='modular_icon'>
                                <img src={icon2} alt="icon2" />
                                </div>
                                <div className='modular_title'>
                                    指标统计分析
                                </div>
                                <div className='modular_content'>
                                    分析指标结果，查找医院管理工作中存在的系统性短板。
                                </div>
                            </div>
                        </div>
                        <div className='modular third'>
                            <div>
                                <div className='modular_icon'>
                                <img src={icon3} alt="icon3" />
                                </div>
                                <div className='modular_title'>
                                    完善工作流程
                                </div>
                                <div className='modular_content'>
                                    自定义过程化管理；流程审批可追溯提升工作效率。
                                </div>
                            </div>
                        </div>
                        <div className='modular fourth'>
                            <div>
                                <div className='modular_icon'>
                                <img src={icon4} alt="icon4" />
                                </div>
                                <div className='modular_title'>
                                    全面辅助提升
                                </div>
                                <div className='modular_content'>
                                    运营管理聚焦问题，全面提升医院整体管理水平。流程审批可追溯提升工作效率。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='successBox'>
                <Carousel afterChange={onChange} autoplay>
                    <div className='banner banner1'>
                        <div className='succecon'>
                            <p>合作案例</p>
                            <img src={prosevhos} />
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}

export default HomePage;
