import { Menu } from 'antd';
import React, { useState, useEffect } from 'react';
import './index.css';
import logo from '../../images/logo.png'
import { NavLink,useLocation } from "react-router-dom";

const items = [
    {
        label: <NavLink to="/index">首页</NavLink>,
        key: '/index',
    },
    {
        label: <NavLink to="/productservice">产品服务</NavLink>,
        key: '/productservice',
    },
    {
        label: <NavLink to="/solution">解决方案</NavLink>,
        key: '/solution',
    },
    {
        label: <NavLink to="/customer">合作案例</NavLink>,
        key: '/customer',
    },
    {
        label: <NavLink to="/aboutus">关于我们</NavLink>,
        key: '/aboutus',
    },
    {
        label: <NavLink to="/recruit">招贤纳士</NavLink>,
        key: '/recruit',
    },
];
const Header = () => {
    const [current, setCurrent] = useState('index');
    let location = useLocation();
    useEffect(() => {
        let pathname = location.pathname;
        setCurrent(pathname)
        window.scrollTo(0,0)
      }, [location]);
    const onClick = (e) => {
        setCurrent(e.key);
    };
    return (
        <div className='HeaderBox'>
            <div className='Header'>
                <div>
                    <img src={logo} alt="logo" />
                </div>
                <div className='right'>
                    <Menu className='menuBox' onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
                </div>
            </div>
        </div>

    );
}

export default Header;
