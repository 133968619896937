import { Row, Col, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css'

const RecruitPage = () => {
    const [position, setPosition] = useState([
        {
            name:"医疗项目经理",
            type: "社会招聘",
            location: "北京",
            num: "3",
            duty: [
                "完善公司的项目管理流程和SOP；",
                "负责交付类项目全过程管理，包括参与售前项目方案准备，项目招投标，沟通客户需求，项目过程实施，验收交付等；",
                "制定项目实施计划，对项目进行全周期管理，负责项目整体规划、组织、跟踪、执行、组织验收交付等管理工作，所负责项目面向公司及客户负责；",
                "协调沟通项目资源，对项目执行过程进行监控，识别和控制项目风险，控制项目进度和质量、成本，确保实现项目目标；",
                "与客户和主要干系人保持顺畅良好沟通，能够很好的理解项目需求、控制项目变更，及时汇报和反馈项目进展情况与阶段性的成果；",
                "理解和收集客户需求，及时反馈，推动产品不断完善，优化解决方案；",
                "发现项目交付过程中的痛点，并结合产品与团队特点，制定有针对性的效率提升方案，包括但不限于工具、流程、培训；",
                "跨部门沟通，协调销售、产品及技术资源，确保项目质量、进度和客户满意度；"
            ],
            requirement: [
                "年龄25-35岁，性别不限，适应出差；",
                "统招本科及以上学历，985/211本科或硕士优先；临床医学、统计学等相关医疗类专业；",
                "3年以上项目管理经验，熟悉项目过程管理，具有软件、系统项目客户交付经验，熟悉卫健、医保政策，有DRG/DIP经验优先；",
                "有项目Owner意识，有突出的项目统筹协调能力和项目推进能力，抗压能力强，适应多线程多任务并行管理，拥有PMP证书优先。"
            ]
        },{
            name:"JAVA开发工程师",
            type: "社会招聘",
            location: "北京",
            num: "2",
            duty: [
                "独立、快速、高质量完成产品业务模块的开发工作，并形成规范的技术文档。",
                "承担部分新技术试验和研究，并应用到公司产品。",
                "解决开发中遇到的实际问题，维护系统的稳定性。",
                "对产品现场实施人员提供技术支持。",
            ],
            requirement: [
                "全日制统招本科及以上学历，学信网可查。2年以上JAVA后端开发经验，具备扎实的计算机理论基础和代码能力，良好的数据结构和算法基础，并能在实际项目中灵活运用。 ",
                "精通java核心技术，对java语言有深刻理解，熟练掌握Spring、SpringBoot、Jpa等常用架构，熟悉微服务架构框架，熟悉常用的MQ消息中间件。 ",
                "熟悉Mysql、Redis等常见数据库，具有数据库设计和开发的能力，能够进行sql优化。",
                "有复杂数据数据平台设计和建设经验优先，有优秀的跨团队沟通协调能力和经验优先。",
                "有一定的Linux基础，有Docker/k8s经验者优先。",
                "有SaaS开发经验优先，有微信小程序/公众号开发经验，微信第三方平台开发经验优先，有医院项目经历者优先。",
                "具有较好的业务学习理解能力，思路清晰，良好的语言表达能力，善于思考和总结。"
            ]
        },{
            name:"区域经理/总监",
            type: "社会招聘",
            location: "全国",
            num: "6",
            duty: [
                "开发区域内的医院客户，根据公司的发展计划完成销售任务。",
                "整理、制作招投标文件，完成对客户的招投标作业。",
                "协助实施人员完成对客户的项目布署及实施、验收。",
                "跟催项目款项，确保回款及时。",
                "客情咨讯及时掌握，收集客户信息，并作好信息的分析归纳及整理，对客户的情况做到精准把控。",
                "对行业信息及产品信息保持高度敏感性，对变化趋势及竞争对手的销售策略、市场策略等能够及时掌握并汇报，能够前瞻性地规划市场和客户的开发策略和节奏。",
                "其他公司及领导交办的任务。",
            ],
            requirement: [
                "专科及以上学历，医学相关专业优先；3年以上院端软件解决方案销售或相关渠道工作及管理经验。 ",
                "熟悉国家公立医院绩效考核或医保局各项业务及DRG/DIP支付方式改革业务顶层设计逻辑。 ",
                "熟悉医院端主流解决方案的功能及主流供应商的市场覆盖情况；熟悉医院内部的组织架构及决策流程。",
                "具有一定的院端渠道关系基础，并具备拓展渠道所必须的路径和支撑条件。",
                "为人诚实稳重，具有较强的责任心、自驱力，优秀的合作协作精神。",
                "有善于沟通、谈判能力和应变能力强，具有较强的逻辑性，能作出高质量的分析或决策。",
            ]
        },{
            name:"医学分析师",
            type: "社会招聘",
            location: "北京",
            num: "1",
            duty: [
                "医学知识库编辑整理工作，包括分析国内外各种临床诊疗规范、医疗文献等。",
                "提取结构化医疗知识和诊疗规则，以及医疗术语校对、归纳和总结等工作。",
                "创建维护诊断手术编码库、卫健质控规则库、医保结算清单校验规则库、医保审核规则库。",
                "熟悉并能反推卫健CN-DRG与医保CHS-DRG。",
                "协助产品经理和开发，为医疗数据产品开发提供医学专业支持，并进行产品功能测试。",
                "产品交付实施中客户医学规则问题答疑，并根据反馈及时优化规则库。",
            ],
            requirement: [
                "全日制统招本科及以上学历，985/211本科或硕士优先。 ",
                "临床医学相关专业。 ",
                "3年以上临床工作经验或医疗行业经验，有竞争公司经验优先。",
                "熟悉国临版、北京版、医保版的ICD10、ICD9编码。",
                "擅长独立分析问题和解决问题，协同执行力强。"
            ]
        }
    ]);
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    return (
        <div className='RecruitPage'>
            <div className='main'>
                <div className='titleBox'>
                    <Row>
                        <Col span={12} className="nameBox">
                            岗位名称
                        </Col>
                        <Col span={12} className="rightBox">
                            <Row>
                                <Col span={8} className="item">招聘类别</Col>
                                <Col span={8} className="item">工作地点</Col>
                                <Col span={8} className="item">需求数量</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='itemBox'>
                    {position.map((el,i)=>{
                        return <div key={i} className="positionBox">
                            <Row className='positiontitle'>
                                <Col span={12} className="nameBox">
                                    {el.name}
                                </Col>
                                <Col span={12} className="rightBox">
                                    <Row>
                                        <Col span={8} className="item">{el.type}</Col>
                                        <Col span={8} className="item">{el.location}</Col>
                                        <Col span={8} className="item">{el.num}</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className='positionmain'>
                                <div>
                                    <p className='dutytitle'>岗位职责：</p>
                                    {el.duty.map((el2,i2)=>{
                                        return <div key={i2}>
                                            {(i2+1)+"、"+el2}
                                        </div>
                                    })}
                                </div>
                                <div>
                                    <p className='dutytitle'>任职要求：</p>
                                    {el.requirement.map((el2,i2)=>{
                                        return <div key={i2}>
                                            {(i2+1)+"、"+el2}
                                        </div>
                                    })}
                                </div>
                                <Link to={"/recruitup/"+el.name+"/"+el.type+"/"+el.location}><div className='btnref'>立即申请</div></Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default RecruitPage;
