
import React from 'react';
import './index.css';
import logo from '../../images/logo_bottom.png';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div className='FooterBox'>
            <div className='Footer'>
                <div className='Footer_top_box'>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className='Footer_text_box Footer_text_box_center'>
                        <div>联系我们</div>
                        <div style={{"color":"#666"}}>hao.shichao@anzun.com.cn</div>
                        <div style={{"color":"#666"}}>地址：北京市朝阳区工人体育场东路百富国际大厦A座7F</div>
                    </div>
                    <div className='Footer_text_box Footer_text_box_right'>
                        <Link to="/aboutus">
                            <div>关于安竣科技</div>
                        </Link>
                        <Link to="/index">
                            <div style={{"color":"#666"}}>安竣科技有限公司介绍</div>
                        </Link>
                        <Link to="/recruit">
                            <div style={{"color":"#666"}}>人才招聘</div>
                        </Link>
                    </div>
                </div>
                <div className='Footer_divider'></div>
                <div className='Footer_bottom_box Footer_text_box'>
                    <div>版权所有：北京安竣科技有限公司</div>
                    <div>ICP主体备案号  京ICP备2021036751号</div>
                </div>
            </div>
        </div>

    );
}

export default Footer;
