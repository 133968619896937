import { Carousel, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import aboutus1 from '../../images/about_1.png';
import aboutusbot1 from '../../images/aboutusbot1.png';
import aboutusbot2 from '../../images/aboutusbot2.png';
import './index.css'

const AboutUsPage = () => {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const onChange2 = (currentSlide) => {
        console.log(currentSlide);
    };
    return (
        <div className='AboutUsPage'>
            <div className='main'>
                <div className='banner_box'>
                    <Carousel afterChange={onChange}>
                        <div className='banner banner1'>
                            <div className='banner_title'>
                                北京安竣科技有限公司
                            </div>
                            <div className='banner_content'>
                                专注中国医院运营管理方向，打造医院精细化管理服务
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className='middleBox'>
                    <div>
                        <p className='title'>关于我们</p>
                        <Row>
                            <Col span={12}>
                                <div className='dutytitle'>北京安竣科技有限公司</div>
                                <div className='con'>
                                    北京安竣科技有限公司长期与清华大学医院管理研究院合作，基于国家多项政策导向，结合医院内部管理经验，契合医院管理发展⽅向，聚焦医院运营指标数据平台，并通过SaaS模式为医院管理提质量、增效益贡献力量。
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <img src={aboutus1} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='botBox'>
                    <div>
                        <p>公司优势</p>
                        <Carousel afterChange={onChange2} autoplay dots={{className:'dotbox'}}>
                            <div className='item'>
                                <img src={aboutusbot1} className="img1" />
                                <img src={aboutusbot2} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPage;
