import { Row, Col, Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import solutbanner2main from '../../images/solutbanner2main.png';
import sulution1 from '../../images/sulution1.png';
import sulution2 from '../../images/sulution2.png';
import sulution3 from '../../images/sulution3.png';
import sulution4 from '../../images/sulution4.png';
import midsult1 from '../../images/midsult1.png';
import midsult2 from '../../images/midsult2.png';
import midsult3 from '../../images/midsult3.png';
import midsult4 from '../../images/midsult4.png';
import midsult5 from '../../images/midsult5.png';
import midsult6 from '../../images/midsult6.png';
import sulutBtnbg1 from '../../images/sulutBtnbg.png';
import sulutBtnbg2 from '../../images/sulutBtnbg2.png';
import sulutBtnbg3 from '../../images/sulutBtnbg3.png';
import './index.css'

const SolutionPage = () => {
    const [imglist] = useState([
        {
            name: "平台稳健",
            imgurl: midsult1,
            title1: "指标体系平台化构建",
            title2: "实时响应政策变化和指标调整"
        },{
            name: "高效准确",
            imgurl: midsult2,
            title1: "高效率完成数据采集",
            title2: "准确计算及指标分析"
        },{
            name: "精细严谨",
            imgurl: midsult3,
            title1: "全面和严谨的数据处理规则",
            title2: "保证数据准确性 "
        },{
            name: "可视分析",
            imgurl: midsult4,
            title1: "图表化的分析报表，增加可读性",
            title2: "有效促进数据分析"
        },{
            name: "安全保障",
            imgurl: midsult5,
            title1: "医院使用模块全部功能，按月付费",
            title2: "降低医院使用风险"
        },{
            name: "专业前瞻",
            imgurl: midsult6,
            title1: "与国内著名学府、⾏业专家开展合作",
            title2: "保持专业领先"
        }
    ]);
    const [menuList,setMenuList] = useState([
        {
            name: '医疗机构',
            check: true,
            title: "完善运营管理流程",
            list: [
                "全局总控管理流程，对医院整体运营情况事前把控，过程化管理。",
                "减少因数据质量问题及填报时间问题，造成的信息偏差。",
                "降低因准确率不达标，造成绩效考核、医院评级的不利后果。"
            ],
            imgurl: sulutBtnbg1
        },{
            name: '质控部门',
            check: false,
            title: "强化运营管理流程",
            list: [
                "为数据准确性及指标上报提供优质数据基础。",
                "分配填报任务、辅助修正数据，有效优化工作流程。",
                "符合国家监测、省市级监测及院内监测，提高管理效率。"
            ],
            imgurl: sulutBtnbg2
        },{
            name: '职能科室',
            check: false,
            title: "提升运营工作效率",
            list: [
                "线下填报提升为线上日常工作，减少操作时间。",
                "指标阈值辅助及参考值比对，提高数据准确性。",
                "从源头提高数据传输能力，及时有效查询修订。"
            ],
            imgurl: sulutBtnbg3
        }
    ])
    const [selectCon,setSelectCon] = useState({
        title: "完善运营管理流程",
        list: [
            "全局总控管理流程，对医院整体运营情况事前把控，过程化管理。",
            "减少因数据质量问题及填报时间问题，造成的信息偏差。",
            "降低因准确率不达标，造成绩效考核、医院评级的不利后果。"
        ],
        imgurl: sulutBtnbg1
    })
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const onChange2 = (currentSlide) => {
        console.log(currentSlide);
    };
    const onChangetab = (index) =>{
        let menu = menuList
        let arr = []
        menu.map((el,i)=>{
            if(i==index){
                setSelectCon({
                    title: el.title,
                    list: el.list,
                    imgurl: el.imgurl
                })
            }
            arr.push({
                ...el,
                check: i==index?true:false
            })
        })
        setMenuList(arr)
    }
    return (
        <div className='SolutionPage'>
            <div className='main'>
                <div className='banner_box'>
                    <Carousel afterChange={onChange}>
                        <div className='banner banner1'>
                            <div>
                                <div className='banner_title'>
                                    医院运营指标数据平台服务方案
                                </div>
                                <div className='banner_content'>
                                    辅助医院进行全面运营诊断与指标数据分析，助力医院优化内部运营管理，有效促进医院高质量发展。
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    <Carousel afterChange={onChange2}>
                        <div className='banner banner2'>
                            <p>医院运营指标数据平台</p>
                            <img src={solutbanner2main} />
                        </div>
                    </Carousel>
                </div>
                <div className='serveBox'>
                    <div>
                        <p>服务内容</p>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <p className='dutytitle'>医院绩效考核模块</p>
                                    <div>
                                        医院绩效考核模块是平台基于国家卫健委绩效考核政策，结合医院信息系统建⽴绩效考核指标体系，全⽅位⽀持国家监测（综合医院26项/中医院34项）、省市级监测及院内⾃有考核指标体系构建，形成医院指标池管理，满足医院日常数据收集上报管理需求并提供数据支撑，协助医院进行全方位的绩效管理，实现对医院内部绩效考核的全⾯⽀撑。
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <img src={sulution1} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <img src={sulution2} />
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p className='dutytitle'>医保结算清单模块</p>
                                    <div>
                                        医保结算清单质控模块是根据医疗保障基金结算清单填写规范及清单数据收集要求，结合医院信息系统建⽴数据质控与优化，从临床医生端入手，生成医保基金结算清单预览，并融入医院流程化管控，辅助医院进行清单数据完善，提升医保基金结算清单的数据治理，达到合理结算，避免基金损失，提升结算经济效益。
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <p className='dutytitle'>DRG临床监测模块</p>
                                    <div>
                                        DRG临床监测模块是结合医保DRG付费政策时间过程中医疗机构实际需求及潜在需求，从多元化复合付费方式改革政策需求出发，为医院用户构建的数据化、标准化、智能化、信息化的运营管理支持工具 。该模块使用院内患者诊疗信息，进行事前及事中监测，辅助医院及时纠正不合理的医疗行为，减少高费用病例的产生，在支付方式改革中占据优势地位。
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <img src={sulution3} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <img className="lastimg" src={sulution4} />
                            </Col>
                            <Col span={12}>
                                <div>
                                    <p className='dutytitle'>DRG智慧管理模块</p>
                                    <div>
                                        DRG智慧管理模块是结合国家医疗保障局医保支付方式改革政策要求，旨在辅助医院进行院内DRG总体管理，实现DRG院内管理和费用结构监控；同时，在医院原有的管理模式基础上，深度挖掘医院盈亏原因，并给与科室及病组管理意见，不断提升在DRG付费改革中医院自主管理和数字化智能管理水平。 
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='planBox'>
                    <p>方案特色</p>
                    <Row>
                        {imglist.map(el=>{
                            return <Col span={8}>
                                <div className='item'>
                                    <img src={el.imgurl} />
                                    <span>{el.name}</span>
                                    <div>
                                        <div>{el.title1}</div>
                                        <div>{el.title2}</div>
                                    </div>
                                </div>
                            </Col>
                        })}
                    </Row>
                </div>
                <div className='sulutBox'>
                    <p>方案价值</p>
                    <div className='widthBox'>
                        <div className='cardBox'>
                            <div className='leftBox'>
                                {menuList.map((el,i)=>{
                                    return <div className={el.check?"checked":""} onClick={e=>onChangetab(i)}>{el.name}</div>
                                })}
                            </div>
                            <div className='con' style={{background: 'url('+selectCon.imgurl+') no-repeat right'}}>
                                <div>
                                    <p className='dutytitle'>{selectCon.title}</p>
                                    <div>
                                        {selectCon.list.map(el=>{
                                            return <div>{el}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SolutionPage;
